import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  buttons: {
    capitalize: false
  },
  iconfont: 'md',
  theme: {
    themes: {
      light: {
        primary: '#3949AB',
        secondary: '#b0bec5',
        accent: '#6A1B9A',
        error: '#b71c1c'
      }
    }
  }
})
