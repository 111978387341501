var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"options":_vm.options,"server-items-length":_vm.totalElements,"loading":_vm.busy,"item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('tr',_vm._l((props.headers),function(header){return _c('td',[_c('v-text-field',{on:{"input":function($event){return _vm.loadData()}},model:{value:(header.find),callback:function ($$v) {_vm.$set(header, "find", $$v)},expression:"header.find"}})],1)}),0)]}},{key:"item.userEntity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.userEntity.firstName + ' ' + item.userEntity.lastName)+" ")]}},{key:"item.project",fn:function(ref){
var item = ref.item;
return [(item.abstractData)?_c('a',{attrs:{"href":("/project/" + (item.abstractData.project.id) + "/home"),"target":"_blank"}},[_vm._v(" "+_vm._s(item.abstractData.project.name)+" ")]):_vm._e()]}},{key:"item.abstractData",fn:function(ref){
var item = ref.item;
return [(item.abstractData)?_c('a',{attrs:{"href":("/project/" + (item.abstractData.project.id) + "/" + (item.abstractData.dataType.toLowerCase()) + "/" + (item.abstractData.id)),"target":"_blank"},on:{"click":function($event){return _vm.openData(item.abstractData)}}},[_vm._v(" "+_vm._s(item.abstractData.name)+" ")]):_vm._e()]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" ")]}},{key:"item.activityType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.activityType))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }