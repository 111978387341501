<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card class="pa-4" style="overflow: hidden">
      <v-card-title class="px-0 pt-0 pb-5">{{ $t('createPrintTemplate') }}</v-card-title>
      <v-text-field v-model="templateName" :label="$t('templateName')" dense hide-details outlined/>
      <v-checkbox v-model="massPrint" label="Массовая печать"/>
      <v-row class="px-3">
        <v-btn
          outlined
          @click="close"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn
          :disabled="templateName.length === 0"
          color="primary"
          outlined
          @click="createPrintTemplate"
        >
          {{ $t('button.create') }}
        </v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/print-template/messages'

export default {
  name: 'CreatePrintTemplateDialog',
  i18n: { messages },
  data: () => ({
    dialog: false,
    templateName: '',
    massPrint: false
  }),
  methods: {
    open () {
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    createPrintTemplate () {
      if (this.templateName) {
        this.$axios.post('print-template/save', {
          name: this.templateName,
          massPrint: this.massPrint
        })
          .then(() => {
            EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
            this.$emit('createdTemplate')
            this.close()
          })
      }
    }
  }
}
</script>
