<template>
  <div v-if="!!data && !!data.id">
    <v-card-title class="px-2 pb-0">
      {{ data.name }}
      <v-spacer/>
      <span class="grey--text">ID:{{ data.id }}</span>
    </v-card-title>
    <div class="px-2">
      <p class="body-2">
        <span class="text--secondary">{{ $t('source') }} </span>
        <span class="font-weight-medium">{{ data.provider.name }}</span>
      </p>
      <span class="headline"></span>
      <div class="body-2 pb-2" v-if="data.description" v-html="description"/>
    </div>
    <v-card-actions>
      <user-avatar :user="data.owner"/>
      <div>
        <span class="body-2">
          {{ data.owner.firstName }}
          {{ data.owner.lastName }}
        </span>
        <br/>
        <span class="body-2">
        {{ formatDate(data.creationDate) }}
      </span>
      </div>
    </v-card-actions>

    <v-divider class="ma-3"/>
    <div class="my-3 px-2" v-if="data.keywords.length > 0">
      <div class="subtitle-1">{{ $t('keyWords') }}</div>
      <v-chip
        class="ma-1"
        color="primary"
        small
        label
        outlined
        v-for="keyword in data.keywords"
      >
        {{ keyword.value }}
      </v-chip>
    </div>

    <div class="my-3 px-2" v-if="data.categoryList.filter(it => !it.isDefault).length">
      <div class="subtitle-1">{{ $t('categories') }}</div>
      <v-chip
        class="ma-1"
        color="deep-purple"
        label
        small
        outlined
        v-for="category in data.categoryList.filter(it => !it.isDefault)"
      >
        {{ category.name }}
      </v-chip>
    </div>
    <relations-data :data="data" class="mt-3 px-2"/>
  </div>
</template>

<script>
import RelationsData from './RelationsData'
import UserAvatar from '@/components/utils/UserAvatar'
import messages from '../../componet-locale/abstract-data-details/messages'

export default {
  name: 'AbstractDataDetails',
  components: { UserAvatar, RelationsData },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  i18n: { messages: messages },
  computed: {
    description () {
      if (this.data.description) {
        return this.data.description.replace(/\n/g, '<br>')
      } else {
        return ''
      }
    }
  }
}
</script>
