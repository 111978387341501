const OPEN_ISSUES = {
  title: 'issueList',
  icon: 'report_problem',
  color: 'white',
  background: '#2d89ff',
  onlyAuthenticated: true,
  action: 'openIssues'
}

const SHARE = {
  title: 'button.share',
  icon: 'link',
  color: 'blue',
  outlined: true,
  background: '#f3f6fc',
  onlyAuthenticated: false,
  action: 'share'
}

const EDIT = {
  title: 'button.edit',
  icon: 'edit',
  color: 'blue',
  outlined: true,
  background: '#f3f6fc',
  onlyAuthenticated: true,
  action: 'edit'
}

const DIRECT_TO_MAP = {
  title: 'button.directToMap',
  icon: 'map',
  color: 'white',
  background: '#2d89ff',
  outlined: true,
  primary: true,
  onlyAuthenticated: true,
  action: 'directToMap'
}

const REMOVE = {
  title: 'delete',
  icon: 'delete_outline',
  color: 'red',
  background: '#ffe0e0',
  outlined: true,
  onlyAuthenticated: true,
  action: 'remove'
}

const POI_INFO = {
  title: 'button.info',
  icon: 'info',
  color: 'white',
  background: '#2d89ff',
  outlined: true,
  onlyAuthenticated: false,
  action: 'info'
}

const ATTACHED_DATA = {
  title: 'button.attachData',
  icon: 'attach_file',
  color: 'blue',
  outlined: true,
  background: '#f3f6fc',
  onlyAuthenticated: false,
  action: 'attachData'
}

const HISTORY = {
  title: 'button.history',
  icon: 'history',
  color: 'blue',
  outlined: true,
  background: '#f3f6fc',
  onlyAuthenticated: true,
  action: 'history'
}

const CALC = {
  title: 'button.calculate',
  icon: 'functions',
  color: 'blue',
  outlined: true,
  background: '#f3f6fc',
  onlyAuthenticated: false,
  action: 'calculate'
}

const PRINT_TEMPLATE = {
  title: 'button.printTemplates',
  icon: 'print',
  color: 'blue',
  outlined: true,
  background: '#f3f6fc',
  onlyAuthenticated: true,
  action: 'printTemplate'

}

const SPACER = {
  spacer: true
}

export {
  OPEN_ISSUES,
  SHARE,
  EDIT,
  DIRECT_TO_MAP,
  REMOVE,
  SPACER,
  POI_INFO,
  ATTACHED_DATA,
  HISTORY,
  CALC,
  PRINT_TEMPLATE
}
