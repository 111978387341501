<template>
  <v-text-field :class=" displayXS ? '' : 'input-filter mr-2'"
                :dense="!displayXS"
                :placeholder="label"
                :outlined="!displayXS"
                :solo="displayXS"
                flat
                background-color="grey lighten-5"
                style="width: 260px !important;"
                @input="$emit('change')"
                clearable
                hide-details
                v-model="model.value">
    <template v-slot:append>
      <v-icon class="pa-1" color="grey" size="20">
        search
      </v-icon>
    </template>
  </v-text-field>
</template>

<script>
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'

export default {
  name: 'TextFieldFilter',
  mixins: [displayServiceMixin],
  props: {
    model: {
      type: Object,
      default: {
        value: ''
      }
    },
    label: {
      type: String,
      default: ''
    }
  }
}
</script>
