<template>
  <div>
    <div class="docx-wrapper">
      <div id="doc" class="doc-body" v-html="docFrame"/>
    </div>
    <v-progress-linear v-show="progress < 100 && progress > 0" :value="progress" class="doc-progress-loader"/>
  </div>
</template>

<script>
import DocDiffDialog from './DocDiffDialog'
import { visualDomDiff } from 'visual-dom-diff'
import Mark from 'mark.js'
import { EventBus } from '../../event-bus'

export default {
  name: 'DocViewer',
  components: { DocDiffDialog },
  props: {
    docBodyList: {
      type: Array,
      default: () => []
    },
    selectedDocBody: {
      type: Object,
      default: () => {
      }
    },
    treeView: {
      type: Array,
      default: () => []
    },
    open: {
      type: Array,
      default: undefined
    },
    activeItems: {
      type: Array,
      default: []
    },
    searchField: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    freeze: false,
    docFrame: '',
    progress: 0,
    titles: [],
    tab: null,
    content: {},
    searchResults: []
  }),
  computed: {
    idBlocks () {
      return this.titles.map(el => el.id)
    }
  },
  mounted () {
    this.content = new Mark(document.querySelector('.doc-body'))
  },
  methods: {
    search () {
      let self = this
      EventBus.$emit('startLoading')
      this.content.unmark({
        done: () => {
          self.content.mark(self.searchField, {
            done: () => {
              EventBus.$emit('endLoading')
              let doc = document.querySelector('.doc-body')
              this.searchResults = doc.querySelectorAll('mark')
              let array = Array.prototype.slice.call(this.searchResults)
              let result = array.map(item => {
                return { html: item.parentElement.innerHTML }
              })
              self.$emit('update:searchResults', result)
              self.jumpTo()
            }
          })
        }
      })
    },
    jumpTo (index = 0) {
      if (this.searchResults.length) {
        this.searchResults.forEach(el => el.classList.remove('highlight'))
        let current = this.searchResults[index]
        current.classList.add('highlight')
        let position = this.searchElementOffsetTop(current)
        document.getElementById('doc-frame').scrollTo(0, position)
      }
    },
    searchElementOffsetTop (element, prevOffset = 0) {
      if (!element.parentNode || element.parentNode.className.includes('doc-body')) {
        return prevOffset + getRealElementOffsetTop(element) - 120
      }
      return this.searchElementOffsetTop(element.parentNode, prevOffset + getRealElementOffsetTop(element))

      function getRealElementOffsetTop (element) {
        switch (element.tagName) {
          case 'MARK':
            return 0
          case 'TR':
            return 0
          default:
            return element.offsetTop
        }
      }
    },
    async findDiff (originalDoc, changedDoc) {
      if (!originalDoc.body) {
        originalDoc.body = await this.loadBody(originalDoc.id)
      }
      if (!changedDoc.body) {
        changedDoc.body = await this.loadBody(changedDoc.id)
      }
      const parser = new DOMParser()
      let originalNode = parser.parseFromString(originalDoc.body, 'text/html')
      let changedNode = parser.parseFromString(changedDoc.body, 'text/html')
      let diff = visualDomDiff(originalNode, changedNode)
      this.docFrame = new XMLSerializer().serializeToString(diff)
    },
    handleScroll () {
      this.idBlocks.find(number => {
        const el = document.getElementById(number)
        if (this.elementInViewport(el)) this.highlight(number)
      })
    },
    highlight (number) {
      if (!this.freeze) {
        this.blockSelecting()
        this.$emit('update:activeItems', [number])
      }
    },
    elementInViewport (el) {
      let top = el.offsetTop
      let height = el.offsetHeight
      while (el.offsetParent) {
        el = el.offsetParent
        top += el.offsetTop
      }
      return (
        top >= document.getElementById('doc-frame').pageYOffset &&
        (top + height) <= (window.pageYOffset + window.innerHeight)
      )
    },
    searchTitles () {
      let id = 0
      let doc = document.getElementById('doc')
      let els = Array.from(doc.querySelectorAll('h1, h2, h3, h4, h5, h6'))
      els.forEach(el => {
        el.id = 'header_' + id++
      })
      this.titles = els.map(element => {
        return {
          id: element.id,
          level: parseInt(element.nodeName.substring(1)),
          name: element.innerText,
          link: `#${element.id}`,
          color: 'black',
          parentId: 0
        }
      })
      this.$emit('update:open', this.titles.map(title => title.id))

      let parents = new Map()
      if (this.titles.length > 1) {
        for (let i = 1; i < this.titles.length - 1; i++) {
          let currentItem = this.titles[i]
          let previousItem = this.titles[i - 1]

          if (currentItem.level === previousItem.level) {
            if (previousItem.parentId) {
              currentItem.parentId = previousItem.parentId
            } else {
              currentItem.parentId = 0
            }
          }

          if (currentItem.level > previousItem.level) {
            let parentId = previousItem.id
            parents.set(currentItem.level, parentId)
            currentItem.parentId = parentId
          }

          if (currentItem.level < previousItem.level) {
            if (parents.get(currentItem.level)) {
              currentItem.parentId = parents.get(currentItem.level)
            } else {
              currentItem.level = 0
            }
          }
        }
      }
      let list = this.titles
      let map = {};
      let node;
      let roots = [];
      let i
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i
      }
      for (i = 0; i < list.length; i += 1) {
        node = list[i]
        if (node.parentId !== '0' && map[node.parentId]) {
          if (!list[map[node.parentId]].children) list[map[node.parentId]].children = []
          list[map[node.parentId]].children.push(node)
        } else {
          roots.push(node)
        }
      }
      this.$emit('update:treeView', roots)
    },
    initListeners () {
      let self = this
      let doc = document.getElementById('doc')
      let config = { attributes: true, childList: true, subtree: true }
      let observer = new MutationObserver((mutationsList, observer) => {
        for (let mutation of mutationsList) {
          if (mutation.type === 'childList') self.searchTitles()
        }
      })
      observer.observe(doc, config)
      document.getElementById('doc-frame').addEventListener('scroll', this.handleScroll)
    },
    blockSelecting () {
      this.freeze = true
      setTimeout(() => {
        this.freeze = false
      }, 20)
    },
    async loadBody (docId, body = '', partNumber = 1) {
      let response = await this.$axios.get('/docs/get-body/' + docId, {
        params: { part: partNumber },
        timeout: 240000
      })
      let part = response.data
      this.progress = partNumber / part.totalParts * 100
      body += part.body

      if (this.isSelectedDoc(docId)) {
        this.docFrame = body
      }

      if (part.totalParts > partNumber) {
        this.loadBody(docId, body, partNumber + 1)
      } else {
        if (this.isSelectedDoc(docId)) {
          this.initListeners()
        }
        return body
      }
    },
    selectDocBody (body) {
      this.$emit('selectDocBody', body)
    },
    isSelectedDoc (docId) {
      return this.selectedDocBody.id === docId
    }
  },
  watch: {
    selectedDocBody: {
      handler: async function (doc) {
        if (this.selectedDocBody.body) {
          this.docFrame = this.selectedDocBody.body
        } else {
          if (this.selectedDocBody.type === 'MS_DOC') {
            this.selectedDocBody.body = await this.loadBody(doc.id)
          }
        }
      },
      deep: true,
      immediate: true
    },
    searchField () {
      // this.search()
    },
    activeItems (val) {
      if (!this.freeze) {
        this.blockSelecting()
        let header = document.getElementById(val)
        if (header) {
          document.getElementById('doc-frame').scrollTo(0, header.offsetTop - 120)
          header.style.backgroundColor = 'yellow'
          setTimeout(() => header.style.backgroundColor = 'white', 1000)
        }
      }
    }
  }
}
</script>
