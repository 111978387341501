<template>
  <feature-card @close="close">
    <template v-slot:name>
      {{ name || type }}
    </template>
    <template v-slot:info>
      <zws-action-toolbar
        :feature="feature"
        :name="name || type"
        @close="$emit('close')"
      />
    </template>

    <template v-slot:fields>
      <zws-fields :feature="feature" :read-only-mode="isAuthenticated"/>
    </template>

    <template v-slot:actions>
      <v-spacer/>
      <v-btn
        outlined
        @click="$refs.featureDetailsDlg.open(feature)"
      >
        <v-icon left>description</v-icon>
        Детали
      </v-btn>
      <v-btn
        v-if="setAbility('MODERATOR')"
        color="primary"
        @click="save">
        {{ $t('button.save') }}
      </v-btn>

      <feature-details-dialog ref="featureDetailsDlg"/>
    </template>
  </feature-card>
</template>

<script>
import FeatureCard from '@/components/map/FeatureCard'
import ZwsCommandBuilder from '@/services/zws-command-builder'
import FeatureDetailsDialog from '@/components/map/zws/ZwsFeatureDetailsDialog'
import ZwsFields from '@/components/map/zws/ZwsFields'
import ZwsActionToolbar from '@/components/map/zws/ZwsActionToolbar'

export default {
  name: 'ZwsFeatureCard',
  components: { ZwsActionToolbar, ZwsFields, FeatureDetailsDialog, FeatureCard },
  props: { feature: Object },
  methods: {
    save () {
      ZwsCommandBuilder.updateElemAttributes(this.feature)
    },
    close () {
      this.$emit('close')
    }
  },
  computed: {
    name () {
      let nameProperty = this.feature.props.find(feature => feature.name.split('::')[1].toLowerCase() === 'dname')
      if (nameProperty && nameProperty.value) {
        return nameProperty.value
      } else return null
    },
    type () {
      if (!this.feature.layer || !this.feature.layer.typeList) return ' - '
      let type = this.feature.layer.typeList
        .find(type => type.typeId === this.feature.typeId)
      return type ? type.name : 'Object'
    }
  }
}
</script>
