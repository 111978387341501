<template>
  <div>
    <v-dialog
      v-model="dialog"
      hide-overlay
      max-width="600"
    >
      <v-card>
        <v-card-title class="flex-nowrap justify-space-between">
          <h3 class="text-truncate">{{ chat.name }}</h3>
          <v-icon @click="dialog = false">close</v-icon>
        </v-card-title>
        <v-card-actions class="px-6" style="height: 60px">
          <span>{{ $t('inviteToChat') }}</span>
          <v-spacer/>
          <v-btn v-if="newMembers.length > 0"
                 color="blue"
                 outlined
                 @click="inviteMembers">
            {{ $t('invite') }}
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <v-autocomplete
            v-model="newMembers"
            :item-text="(item) => (`${item.firstName} ${item.lastName}`)"
            :items="userList"
            :placeholder="$t('selectParticipants')"
            :search-input.sync="search"
            chips
            deletable-chips
            dense
            hide-details
            hide-no-data
            multiple
            outlined
            return-object
            @focus="find('')"
          >
            <template v-slot:selection="{ item }">
              <template>
                <v-chip>
                  <user-avatar
                    :user="item"
                    small
                  />
                  {{ getFullName(item) }}
                </v-chip>
              </template>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-avatar>
                <user-avatar
                  :user="item"
                  dense
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="getUserFullName(item)"/>
                <v-list-item-subtitle v-text="getUserInfo(item)"/>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-divider/>
          <v-list>
            <v-subheader>
              {{ $t('chatParticipants') }}
              <span class="pl-2 blue--text font-weight-bold">{{ members.length }}</span>
            </v-subheader>
            <v-list-item
              v-for="item in members"
              :key="item.id"
            >
              <v-list-item-avatar>
                <user-avatar
                  :user="item"
                  dense
                />
              </v-list-item-avatar>
              <v-list-item-content>
                {{ item.firstName }} {{ item.lastName }}
                <v-list-item-subtitle v-if="chat.ownerID === item.id">{{ $t('chatCreator') }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="chat.type === 'PRIVATE'">
                <v-icon
                  small
                  @click="callConfirmation(item)"
                  v-text="'close'"
                />
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <chat-confirmation-dialog
      ref="confirmation"
      @confirm="remove"
    />
  </div>
</template>

<script>
import messages from '../../componet-locale/chat-invitation-dialog/messages'
import UserAvatar from '../utils/UserAvatar'
import ChatConfirmationDialog from './ChatConfirmationDialog'
import { chatMixin } from './mixins/chat-mixin'

export default {
  name: 'ChatInvitationDialog',
  components: { UserAvatar, ChatConfirmationDialog },
  mixins: [chatMixin],
  data: () => ({
    chat: {},
    dialog: false,
    members: [],
    memberToRemove: undefined,
    newMembers: [],
    search: '',
    userList: []
  }),
  i18n: { messages: messages },
  methods: {
    callConfirmation ({ id, firstName, lastName }) {
      this.memberToRemove = id
      const title = `${this.$t('button.remove')} ${firstName} ${lastName} ${this.$t('fromChat')} ${this.chat.name}?`
      const body = this.$t('userNoParticipation')
      this.$refs.confirmation.open(title, body)
    },
    find (val = '') {
      this.$axios
        .get('users/users-not-members', {
          params:
            { str: val, chat: this.chat.id } // with empty 'val' returns first 10 rows
        })
        .then(response => this.userList = response.data)
    },
    inviteMembers () {
      const membersIds = this.newMembers.map(i => i.id)
      this.$axios
        .post('chats/invite', membersIds, { params: { chatId: this.chat.id } })
        .then(() => {
          this.takeMembers()
          this.newMembers = []
        })
    },
    remove () {
      this.$axios
        .get('chats/detach-member', {
          params: {
            member: this.memberToRemove,
            chat: this.chat.id
          }
        })
        .then(() => this.takeMembers())
    },
    open (chat) {
      this.chat = { ...chat }
      this.takeMembers(() => this.dialog = true)
    },
    takeMembers (callback = null) {
      this.$axios
        .get('chats/members', { params: { chatId: this.chat.id } })
        .then(response => {
          this.members = response.data
          if (callback) callback()
        })
    }
  },
  watch: {
    dialog (val) {
      if (!val) this.newMembers = []
    },
    search (val) {
      if (val && val.length) {
        this.find(val)
      }
    }
  }
}
</script>
