<template>
  <v-form
    lazy-validation
    ref="form"
    v-model="valid"
  >
    <v-text-field
      :rules="[v => !!v || 'Поле должно быть заполнено']"
      class="mt-1"
      dense
      :label="$t('name')"
      outlined
      v-model="item.name"
    />
    <slot/>
    <v-combobox
      :items="providerList"
      :rules="[v => !!v || 'Поле должно быть заполнено']"
      dense
      item-text="name"
      :label="$t('source')"
      outlined
      return-object
      v-model="item.provider"
    >
    </v-combobox>

    <v-textarea
      dense
      :label="$t('description')"
      outlined
      auto-grow
      counter="1024"
      rows="1"
      v-model="item.description"
    />

    <v-select
      v-model="item.status"
      :label="$t('adStatus.label')"
      :items="adStatusList"
      dense
      outlined
    >
      <template v-slot:selection="{item}">
        {{ $t(`adStatus.${item}`) }}
      </template>
      <template v-slot:item="{item}">
        {{ $t(`adStatus.${item}`) }}
      </template>
    </v-select>
    <v-combobox
      :items="keywordList" chips
      clearable
      dense
      item-text="value"
      :label="$t('keyWords')"
      multiple
      outlined
      return-object
      v-model="item.keywords"
    >
      <template v-slot:selection="data">
        <v-chip
          @click:close="removeChips(data.item, item.keywords)" close label small>
          {{ data.item.value }}
        </v-chip>
      </template>
    </v-combobox>
    <v-select
      v-if="categoryList.length > 1"
      :items="categoryList.filter(it => !it.isDefault)" chips
      clearable
      dense
      item-text="name"
      :label="$t('categories')"
      multiple
      outlined
      return-object
      v-model="item.categoryList"
    >
      <template v-slot:selection="data">
        <v-chip @click:close="removeChips(data.item, item.categoryList)" close label small>
          {{ data.item.name }}
        </v-chip>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ item.type }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-select>
  </v-form>
</template>

<script>
import messages from '../../componet-locale/abstract-data-edit-form/messages'

export default {
  name: 'AbstractDataEditForm',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  i18n: { messages: messages },
  data: () => ({
    adStatusList: ['NO_DATA', 'DATA_RECEIVED', 'CHECKED', 'NEEDS_IMPROVEMENT'],
    valid: true,
    requiredFieldRules: [
      v => !!v || 'Поле должно быть заполнено'
    ],
    providerList: [],
    keywordList: [],
    languages: [],
    categoryList: []
  }),
  mounted () {
    this.init()
  },
  watch: {
    'item.keywords' (array) {
      this.chipsListener(array)
    },
    'item.provider' (val) {
      if (typeof val === 'string' && !!val.length) {
        this.item.provider = {
          name: val,
          description: '_',
          project: this.selectedProject
        }
      }
    }
  },
  methods: {
    validateForm () {
      return this.$refs.form.validate()
    },
    init () {
      let projectId = this.selectedProject.id
      this.$axios
        .get('/languages/get-all')
        .then(response => {
          this.languages = response.data
        })
      this.$axios
        .get('/provider/get-all-for-project/', { params: { id: projectId } })
        .then(response => {
          this.providerList = response.data
          if (!this.item.provider && this.providerList.length) {
            this.item.provider = this.providerList[0]
          }
        })
      this.$axios
        .get('/keyword/get-all-for-project/', { params: { id: projectId } })
        .then(response => {
          this.keywordList = response.data
        })
      this.$axios
        .get('/category/get-all-for-project/', { params: { id: projectId } })
        .then(response => {
          this.categoryList = response.data
        })
    },
    chipsListener (array) {
      let project = this.selectedProject
      for (let i = 0; i < array.length; i++) {
        if (typeof array[i] === 'string') {
          let existingKeyword = this.keywordList.find(it => it.value === array[i])
          if (existingKeyword) {
            array[i] = existingKeyword
          } else {
            array[i] = { value: array[i], project: project }
          }
        }
      }
    },
    removeChips (item, array) {
      array.splice(array.indexOf(item), 1)
    }
  }
}
</script>
