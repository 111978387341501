<template>
  <div>
    <v-btn @click="createChart" class="ma-2" outlined v-if="setAbility('MODERATOR') && !widget">
      {{ $t('addChart') }}
    </v-btn>
    <v-btn
      @click="createItem"
      class="ma-2"
      outlined
      v-if="setAbility('MODERATOR') && !widget"
    >
      Добавить запись
    </v-btn>
    <v-data-table
      v-if="showTable"
      :custom-filter="customFilter"
      :headers="convertedHeaders"
      :items="table.items"
      :search="search"
      class="elevation-0 pa-0">
      <template v-slot:header="{ props }">
        <tr>
          <td v-for="header in props.headers">
            <v-text-field v-model="header.find" v-on:input="updateFilter(header)"/>
          </td>
        </tr>
      </template>
      <template v-slot:item="{ item, isSelected }">
        <tr :active="isSelected" @click="openItem(item)">
          <td
            v-if="header.name !== 'url'"
            :class="header.styleCell"
            v-for="header in table.headers"
          >
            {{ getCellValue(item, header) }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <data-table-item-details-dialog
      @save="$emit('save')"
      :table="table"
      ref="detailsDlg"
    />
    <chart-edit-dialog :table="table" ref="chartDialog"/>
  </div>
</template>

<script>
import ChartEditDialog from '../chart/ChartEditDialog'
import messages from '../../componet-locale/data-table/messages'
import DataTableItemDetailsDialog from '@/components/table/DataTableItemDetailsDialog'

export default {
  name: 'DataTable',
  components: { DataTableItemDetailsDialog, ChartEditDialog },
  props: {
    table: {
      type: Object,
      default () {
        return {
          headers: []
        }
      }
    },
    widget: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    search: '{}',
    complexSearch: {},
    convertedHeaders: [],
    showTable: true,
    isLoading: false
  }),
  i18n: { messages: messages },
  methods: {
    refresh () {
      this.showTable = false
      setTimeout(() => this.showTable = true, 100)
    },
    createChart () {
      this.$refs.chartDialog.open()
    },
    openItem (item) {
      this.$refs.detailsDlg.open(item, this.table.headers)
    },
    createItem () {
      this.$refs.detailsDlg.createItem()
    },
    customFilter (_, search, row) {
      if (search === '{}') return true
      const parsedSearch = JSON.parse(search)
      return Object.keys(parsedSearch)
        .every(key => `${row[key]}`.toLowerCase()
          .includes(parsedSearch[key].toLowerCase()))
    },
    updateFilter (header) {
      this.complexSearch[header.value] = header.find ? header.find : undefined
      this.search = JSON.stringify(this.complexSearch)
    },
    getCellValue (item, header) {
      function fixed (x, d) {
        if (!d) return x.toFixed(d)
        return x.toFixed(d).replace(/\.?0+$/, '')
      }
      let value = item[header.field]
      if (typeof value === 'number') return fixed(item[header.field], 4)
      else return value
    }
  },
  watch: {
    'table.headers' (headers) {
      this.convertedHeaders = headers
        .filter(header => header.name !== 'url')
        .map(header => {
          return {
            text: header.name,
            value: header.field,
            find: ''
          }
        })
    }
  }
}
</script>

<style>
th {
  border-bottom: none !important;
}

td > .v-input {
  padding-top: 0;
  margin-top: 0;
}
</style>
