<template>
  <div>
    <v-dialog
      persistent
      v-model="dialog"
      width="600"
      scrollable
    >
      <v-card style="max-height: 90vh">
        <v-card-title>
          {{$t("tasks")}}
          <v-spacer/>
          <v-btn icon @click="dialog=false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-list v-if="issues && issues.length!==0" dense subheader>
            <template v-for="issue in issues">
              <v-list-item :key="issue.id" @click="() => goToTask(issue.id)">
                <v-list-item-avatar>
                  <v-avatar :color="getStatus(issue.status).color" class="mx-3" size="20"/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ issue.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ getUserFullName(issue.reporter) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action-text>
                  {{ formatDate(issue.creationDate) }}
                </v-list-item-action-text>
              </v-list-item>
            </template>
          </v-list>
          <v-card
              v-else
              outlined
              class="mx-2 mb-2"
          >
            <v-card-text>
              {{$t('noTasksForTheObject')}}
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn
            outlined
            color="black"
            @click="close"
          >
            {{$t("cancel")}}
          </v-btn>
          <v-spacer/>
          <v-btn
            outlined
            color="primary"
            @click="createIssue"
          >
            {{$t("create")}}
            <v-icon right>create</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <new-issue-dialog
      v-if="isAuthenticated"
      @on-save-issue="onSaveIssue"
      :map-widget="false"
      ref="newIssueDialog"
    />
  </div>
</template>

<script>

import NewIssueDialog from '@/components/issue/NewIssueDialog.vue'
import UserAvatar from '@/components/utils/UserAvatar.vue'
import { issueMixin } from '@/mixins/issue-mixin'
import messages from '@/componet-locale/layer-poi-issues-dialog/messages'

export default {
  name: "LayerPoiIssuesDialog",
  components: { UserAvatar, NewIssueDialog },
  data: () => ({
    poi: {},
    dialog: false,
    issues: []
  }),
  i18n:{messages},
  mixins: [issueMixin],
  methods: {
    init (id) {
      this.$axios
        .get('issue/get-issues-by-layer-id', {
          params: { layerId: id }
        })
        .then(res => { this.issues = res.data })
    },
    open (poi) {
      this.dialog = true
      this.poi = { ...poi }
      this.init(this.poi.id)
    },
    close (){
      this.dialog = false
    },
    createIssue () {
      this.$refs.newIssueDialog.createLayerPoiIssue(this.poi)
    },
    onSaveIssue () {
      this.$emit('on-save-issue')
      this.init(this.poi.id)
    },
    goToTask(isId){
      this.$router.push(`/project/${this.selectedProject.id}/issue/${isId}`)
    }
  }
}
</script>
