<template>
  <v-data-table
    :items="items"
    :headers="headers"
    :options.sync="options"
    :server-items-length="totalElements"
    :loading="busy"
    item-key="id">

    <template v-slot:header="{ props }">
      <tr>
        <td v-for="header in props.headers">
          <v-text-field v-model="header.find" v-on:input="loadData()"/>
        </td>
      </tr>
    </template>

    <template v-slot:item.userEntity="{ item }">
      {{ item.userEntity.firstName + ' ' + item.userEntity.lastName }}
    </template>

    <template v-slot:item.project="{ item }">
      <a
        v-if="item.abstractData"
        :href="`/project/${item.abstractData.project.id}/home`"
        target="_blank"
      >
        {{ item.abstractData.project.name }}
      </a>
    </template>

    <template v-slot:item.abstractData="{ item }">
      <a
        v-if="item.abstractData" @click="openData(item.abstractData)"
        :href="`/project/${item.abstractData.project.id}/${item.abstractData.dataType.toLowerCase()}/${item.abstractData.id}`"
        target="_blank"
      >
        {{ item.abstractData.name }}
      </a>
    </template>

    <template v-slot:item.date="{ item }">
      {{ formatDate(item.date) }}
    </template>

    <template v-slot:item.activityType="{ item }">
      {{ $t(item.activityType) }}
    </template>

  </v-data-table>
</template>

<script>
import messages from '@/componet-locale/user-activity/messages'

export default {
  name: 'UserActivity',
  i18n: { messages: messages },
  data: () => ({
    cancel: undefined,
    busy: false,
    totalElements: 0,
    options: {},
    items: []
  }),
  methods: {
    loadData () {
      const CancelToken = this.$axios.CancelToken
      this.busy = true
      let self = this

      if (this.cancel) {
        this.cancel()
      }

      let criteria = this.headers
        .map(header => {
          return {
            name: header.value,
            value: header.find
          }
        })

      const { sortDesc, sortBy, page, itemsPerPage } = this.options

      let searchRequest = {
        page: page,
        desc: 'DESC',
        sortBy: 'id',
        rowsPerPage: itemsPerPage
      }

      if (sortBy.length > 0) {
        searchRequest.sortBy = sortBy[0]
        searchRequest.desc = sortDesc[0] ? 'DESC' : 'ASC'
      }
      this.$axios
        .post('admin/activity-log/find-pageable-search-result', searchRequest, {
          cancelToken: new CancelToken(function executor (c) {
            self.cancel = c
          })
        })
        .then(response => {
          this.items = response.data.content
          this.totalElements = response.data.totalElements
        })
        .catch(thrown => {
          if (this.$axios.isCancel(thrown)) {
            console.error('Request canceled', thrown.message)
          } else {
            thrown ? console.error(thrown.toString()) : console.error('Unknown exception')
          }
        })
        .finally(() => this.busy = false)
    }
  },
  computed: {
    headers () {
      return [
        { text: 'Пользователь', value: 'userEntity' },
        { text: 'Проект', value: 'project' },
        { text: 'Документ', value: 'abstractData' },
        { text: 'Описание', value: 'description' },
        { text: 'Активность', value: 'activityType' },
        { text: 'Дата и время', value: 'date' }
      ]
    }
  },
  watch: {
    options: {
      handler () {
        this.loadData()
      },
      deep: true
    }
  }
}
</script>
