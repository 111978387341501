<template>
  <v-dialog
    persistent
    v-model="dialog"
    scrollable
    width="800"
  >
    <v-card v-if="dialog">
      <v-card-title>
        Загрузка данных
        <v-spacer/>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-select
          v-if="categoryList.length > 1"
          :items="categoryList.filter(it => !it.isDefault)"
          chips
          clearable
          item-text="name"
          label="Выберите категорию для загружаемых документов"
          multiple
          outlined
          return-object
          v-model="selectedCategoryList"
        >
          <template v-slot:selection="data">
            <v-chip
              @click:close="removeChips(data.item, selectedCategoryList)"
              close label
            >
              {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.type }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-select>
        <file-pond
          v-if="!showItemList"
          :allow-multiple="true"
          :server="myServer"
          @addfilestart="isFileUploaded = false"
          @processfiles="isFileUploaded = true"
          label-idle="Нажмите, чтобы загрузить файл или просто перетащите файлы сюда"
          max-files="100"
          ref="pond"
        />

        <v-expansion-panels
          v-else-if="items.length > 1"
          focusable
          multiple
        >
          <v-expansion-panel
            v-if="showExpansionPanel"
            v-for="(item,i) in items"
            :key="'ad-' + i"
          >
            <v-expansion-panel-header disable-icon-rotate>
              <template v-slot:actions>
                <v-icon color="error" v-if="item.providerIsEmpty">
                  error
                </v-icon>
                <v-icon v-else>
                  tune
                </v-icon>
              </template>
              {{ item.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <abstract-data-edit-form
                validate-on-create
                class="pa-3"
                :item="item"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <div
          v-else-if="items.length === 1"
          class="pa-3"
        >
          <abstract-data-edit-form
            class="pa-3"
            :item="items[0]"
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-if="showItemList"
          color="primary"
          @click="saveAll"
        >
          {{ $t('button.save') }}
        </v-btn>
        <v-btn
          v-else-if="items.length > 0"
          color="primary"
          :disabled="!isFileUploaded && !isFileConverted"
          @click="next"
        >
          {{ $t('button.next') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import vueFilePond from 'vue-filepond'
import mammoth from 'mammoth'
import { EventBus } from '@/event-bus'
import AbstractDataEditForm from '@/components/utils/AbstractDataEditForm'

const FilePond = vueFilePond()

export default {
  name: 'DocUploadDialog',
  components: { AbstractDataEditForm, FilePond },
  data: function () {
    return {
      dialog: false,
      isFileUploaded: false,
      categoryList: [],
      selectedCategoryList: [],
      isFileConverted: false,
      showExpansionPanel: true,
      showItemList: false,
      items: [],
      myServer: {
        process: (fieldName, file, metadata, load) => {
          this.handleFileUpload(file, load)
        }
      }
    }
  },
  methods: {
    open () {
      this.showItemList = false
      this.isFileUploaded = false
      this.isFileConverted = true
      this.items = []
      this.dialog = true

      this.$axios
        .get('/category/get-all-for-project/', { params: { id: this.selectedProject.id } })
        .then(response => {
          this.categoryList = response.data
        })
    },
    handleFileUpload (file, load) {
      let item = {}
      let tempFile = file
      let type = 'OTHER'
      let reader = new FileReader()
      let fileExt = tempFile.name.split('.').pop().toLowerCase()
      item.name = tempFile.name.replace(/\.[^/.]+$/, '')
      if (fileExt === 'pdf') {
        type = 'PDF'
      }
      let self = this
      if (fileExt === 'docx') {
        this.isFileConverted = false
        type = 'MS_DOC'
        reader.onload = () => {
          let arrayBuffer = reader.result
          mammoth.convertToHtml({ arrayBuffer: arrayBuffer })
            .then(function (result) {
              item.firstVersion.value = result.value
              self.isFileConverted = true
            })
            .catch(() => {
              EventBus.$emit('showErrorMessage', 'Ошибка чтения файла')
            })
            .done()
        }
      }
      reader.readAsArrayBuffer(tempFile)
      item.firstVersion = { type: type }
      this.saveFile(load, tempFile, item)
    },
    saveFile (load, tempFile, item) {
      let formData = new FormData()
      formData.append('file', tempFile)
      this.$axios
        .post('/files/save', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          timeout: 240000
        })
        .then(response => {
          item.filePath = response.data
          item.firstVersion.fileName = tempFile.name
          load()
          this.items.push(item)
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', 'error')
        })
    },
    async next () {
      this.items.forEach(it => {
        it.categoryList = this.selectedCategoryList
      })
      let providerResponse = await this.$axios.get('/provider/get-all-for-project/', { params: { id: this.selectedProject.id } })
      let providerList = providerResponse.data

      if (providerList.length > 0) {
        this.items.forEach(item => {
          item.provider = providerList[0]
        })
      }
      this.showItemList = true
    },
    saveAll () {
      let projectId = this.selectedProject.id
      this.items.forEach(item => {
        item.providerIsEmpty = false
      })
      let itemsWithoutProvider = this.items.filter(it => !it.provider)
      if (itemsWithoutProvider.length > 0) {
        itemsWithoutProvider.forEach(item => {
          item.providerIsEmpty = true
        })
        this.showExpansionPanel = false
        this.showExpansionPanel = true
        EventBus.$emit('showErrorMessage', 'Часть документов не имеет источника данных')
        return
      }
      this.$axios
        .post('/docs/save-all/' + projectId, this.items, { timeout: 120000 })
        .catch(reason => {
          console.error(reason.data.message)
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
        .finally(() => {
          this.$emit('onSave')
          this.dialog = false
        })
    },
    removeChips (item, array) {
      array.splice(array.indexOf(item), 1)
    }
  }
}
</script>
